.playing-mode-picking-header {
    display: flex;
    width: 435px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

}

#playing-mode-page-button-singleplayer {
    order: 1;
}

#playing-mode-page-button-splitscreen {
    order: 2;
}

@media (max-width: 680px) {
   .playing-mode-picking-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
   } 
}