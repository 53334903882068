:root {
    --sidebar-width: 200px;
}

#sidebar {
    background-color: #343434;
    color: white;
    height: 100vh;
    width: var(--sidebar-width);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

a, a:active, a:visited {
    color: inherit;
}

#sidebar-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 10px;
    cursor: pointer;
}

#sidebar-logo {
    width: 60px;
    height: 60px;
    object-fit: contain;
    object-position: center;
}

.sidebar-section {
    padding: 10px;
    font-size: 18px;
    width: calc(100% - 10px);
}

.sidebar-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
    cursor: pointer;
}

*:is(.sidebar-section-header, .sidebar-link):hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.sidebar-link {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    transition: font-weight 200ms linear;
    padding: 5px 0 5px 10px !important;
}

.sidebar-link.active {
    font-weight: 900;
    color: var(--accent-yellow);
}

#sign-out-button.active {
    font-weight: 400;
    color: white;
}

#sign-out-sidebar-link-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

#current-user {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    word-break: break-all;
}