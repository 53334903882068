#content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.dash-device {
    display: flex;
    flex-direction: row;
}

#dashboard-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.dashboard-picker-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 20px;
}

#dashboard-content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.dash-widget {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 200px;
    gap: 10px;
    padding: 10px;
    border-radius: var(--big-br);
    background-color: #ccc;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.dash-widget.showing-info {
    flex-direction: column-reverse;
}

.dash-widget canvas {
    position: relative;
    z-index: 2;
    width: 100%;
}

.dash-widget-value {
    position: relative;
    font-weight: 900;
    font-size: 36px;
    max-height: 90%;
}

.dash-widget-title.content-title {
    margin-top: -10px;
}

.dash-widget-center-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    word-wrap: normal;
    font-size: 36px;
    z-index: 1;
}

.dash-widget-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: white;
    padding: 10px;
    height: 100%;
    width: calc(100% - 20px);
    overflow: scroll;
    white-space: nowrap;
}

#dashboard-worst-case-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dash-widget-info-item {
    text-decoration: none;
    width: fit-content;
}

.dash-widget-info-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
    text-decoration: none;
}