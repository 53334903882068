.device-picking-header {
    display: flex;
    width: 435px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.page-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    background-color: #ccc;
    border-radius: var(--small-br);
    padding: 14px;
}

.page-button {
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.page-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.page-button.active::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: var(--accent-yellow);
    bottom: -8px;
    left: 0;
}

.page-icon {
    width: 26px;
    height: 26px;
    padding: 2px;
    scale: 0.7;
    object-position: center;
    object-fit: 200%;
    transition: scale 200ms linear;
}

.page-button.active>.page-icon {
    scale: 1;
}

@media (max-width: 680px) {
   .device-picking-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
   } 
}