#sign-in-page {
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 100px;
    align-items: center;
    height: calc(100vh - 40px);
    background-image: url(../res/full-wave.svg);
    background-color: #34343489;
    background-position: bottom center;
    background-size: cover;
}

#sign-in-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    text-align: center;
}

#sign-in-header-logo {
    width: 50px;
}

#sign-in-header-title {
    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#sign-in-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    color: black;
    background-color: #ccc;
    padding: 20px;
    border-radius: var(--big-br);
    box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.1);

}

#sign-in-with-google-button {
    width: 200px;
    padding: 10px 20px;
    background-color: white;
    border-radius: var(--small-br);
    border: 2px solid #b7bbc1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    cursor: pointer;
}

#sign-in-with-google-button:hover {
    background-color: #e9e9e9;
}

#google-icon {
    width: 25px;
}

