body {
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    margin: 0;
    padding: 0;
}

:root {
    font-family: Lato, sans-serif;
    scrollbar-width: none;
    --accent-orange: #FF6600;
    --accent-yellow: #FBAC0D;
    --accent-yellow-transparent: #FBAC0D80;
    --big-br: 18px;
    --small-br: 12px;

    --loading-circle-size: 120px;
    --loading-circle-progress: 10%;
}

::-webkit-scrollbar {
    display: none;
}
  
.display-none {
    display: none !important;
}

#loading-view {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#loading-circle {

    position: relative;

    width: var(--loading-circle-size);
    height: var(--loading-circle-size);
    border-radius: 50%;
    z-index: 3;
    background-image: conic-gradient(var(--accent-orange) 0%, var(--accent-orange) var(--loading-circle-progress), transparent var(--loading-circle-progress));

    animation: spin 1.5s linear infinite;

}

#loading-circle::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(var(--loading-circle-size) - 16px);
    height: calc(var(--loading-circle-size) - 16px);
    border-radius: 50%;

    background-image: conic-gradient(#343434 0%, #343434 100%);

    translate: -50% -50%;
}

#loading-circle::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(var(--loading-circle-size) - 32px);
    height: calc(var(--loading-circle-size) - 32px);
    z-index: 2;
    border-radius: 50%;

    background-image: conic-gradient(var(--accent-yellow) 0%, var(--accent-yellow) var(--loading-circle-progress), transparent var(--loading-circle-progress));

    translate: -50% -50%;
}

#loading-circle-cover {
    position: absolute;
    z-index: 4;
    top: 50%;
    left: 50%;
    width: calc(var(--loading-circle-size) - 44px);
    height: calc(var(--loading-circle-size) - 44px);
    border-radius: 50%;
    background-color: white;
    translate: -50% -50%;

}

#main-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start
}

.content-container {
    padding-top: 40px;
}

#layout-body {
    position: relative;
    padding: 20px 0 0 20px;
    width: calc(100vw - var(--sidebar-width) - 20px);
}

#sidebar.sidebar-inline + #layout-body {
    margin-left: var(--sidebar-width);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg)
    }
    
}