.worst-case-section-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}

.worst-case-widget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #ccc;
    border-radius: var(--big-br);
    width: 220px;
}

.worst-case-widget.green {
    background-color: #8bd38b;
}

.worst-case-widget.yellow {
    background-color: #f4e775;
}

.worst-case-widget.red {
    background-color: #e37e7e;
}

.worst-case-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.worst-case-title {
    font-weight: 700;
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.worst-case-value {
    font-weight: 900;
    font-size: 30px;
}

.worst-case-chart {
    background-color: white;
    border-radius: var(--small-br);
    width: calc(100% - 20px);
    max-width: 200px;
    height: 120px;
}