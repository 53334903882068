.test-table {
    /* width: 100%; */
    border-collapse: collapse;
    text-align: left;
    table-layout: fixed;
    height: fit-content;
}

.test-table-th-title {
    gap: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.test-table-th-subtitle {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.test-table td, .test-table th {
    border: 1px solid black;
    padding: 20px 5px;
}

.cell-inner {
    display: flex;
    flex-direction: row;
    min-width: 150px;
    justify-content: space-between;
}

.cell-change {
    font-size: 12px;
}

:is(.test-table-link, .hover-decoration-link):not(:hover) {
    text-decoration: none;
}
