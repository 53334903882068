@font-face {
    font-family: 'Lato';
    src: url('../res/Lato/Lato-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lato';
    src: url('../res/Lato/Lato-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Lato';
    src: url('../res/Lato/Lato-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lato';
    src: url('../res/Lato/Lato-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Lato';
    src: url('../res/Lato/Lato-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Lato';
    src: url('../res/Lato/Lato-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lato';
    src: url('../res/Lato/Lato-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Lato';
    src: url('../res/Lato/Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lato';
    src: url('../res/Lato/Lato-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lato';
    src: url('../res/Lato/Lato-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
  }
  