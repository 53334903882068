.stats-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* gap: 40px; */
    /* height: 100%; */
    margin-bottom: 20px;
}

.stats-chart {
    width: 500px;
    height: 300px;
    padding: 20px;
    cursor: pointer;
}

.selected-chart {
    background-color: var(--accent-yellow-transparent);
    border-radius: var(--big-br);
    
}

.stats-chart-header {
    /* text-align: center; */
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
