.collapsible-section-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    /* padding: 5px; */
}

.collapsible-section-header-subtitle {
    font-size: 14px;
}

.big-header>.collapsible-section-header {
    font-size: 20px;
}

.thin-header {
    padding-left: 10px;
}

.collapsible-section-header:not(.sidebar-section-header) {
    padding: 10px;
}

.collapsible-section-header:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.collapsible-section.centered-header>.collapsible-section-header {
    justify-content: center;
}

.collapsible-section-arrow {
    rotate: 0deg;
    transition: rotate 200ms linear;
}

.collapsible-section-content-wrapper {
    display: grid;
    grid-template-rows: 1fr;
    overflow: hidden;
    transition: grid-template-rows 200ms;
}

.closed>.collapsible-section-content-wrapper {
    grid-template-rows: 0fr;
}

.collapsible-section-content {
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

.collapsible-section-content>*:not(.thin-header) {
    padding: 10px;
}

.open>.collapsible-section-header>.collapsible-section-arrow {
    rotate: 180deg;
}